<template>
	<div class="container">
		<Header></Header>
		<div class="wik-top-wrap">
			<div class="wik-top wrap">
				<div class="wik-top-banner clearfix">
					<div class="wik-top-left fl">
						<el-carousel trigger="click" height="330px" class="wik-top-carousel">
							<el-carousel-item v-for="(item,index) in bannerList" :key="index" v-if="item.carouseImagesType=='1'">
								<img class="carousel-img" :src="imgUrl+item.images" width="100%" height="100%">
								<h3 class="wik-top-text">{{item.title}}</h3>
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="wik-top-right fr">
						<ul class="wik-top-rList">
							<li v-for="(item,index) in bannerList" :key="index" v-if="item.carouseImagesType=='2'">
								<a href="#">
									<img :src="imgUrl+item.images" alt="" />
									<div class="wik-top-box">
										<p>{{item.title}}</p>
									</div>
								</a>
							</li>
						</ul>
					</div>
				</div><!-- wik-top-banner end -->
				<div class="wik-top-quick clearfix">
					<ul>
						<li v-for="(item,q) in quick" :key="q" :class="quickIndex == q?'active':''"
							@click="quickBtn(item,q)">
							<span><img :src="item.icon" alt="" /></span>
							<p>{{item.name}}</p>
						</li>
					</ul>
				</div><!-- wik-top-quick end -->
			</div><!-- wik-top end -->
		</div><!-- wik-top-wrap end -->

		<div class="wik-main wrap clearfix mt-20">
			<div class="wik-left fl">
				<div class="wik-left-m1 mb-20" v-show="leftList.length !=0">
					<ul class="wik-left-m1-list clearfix">
						<li v-for="(item,c) in leftList1" :key="c">
							<div class="wik-left-m1-img">
								<a href="#">
									<img :src="imgUrl+item.images" alt="" />
								</a>
							</div>
							<div class="wik-left-m1-text">{{item.articleSummary}}</div>
							<div class="wik-left-m1-bottom clearfix">
								<div class="wik-left-m1-bl fl">{{item.labels}}</div>
								<div class="wik-left-m1-br fr">
									<span><img src="../../assets/images/icon-see.png" alt="" />{{item.views}}</span>
									<span><img src="../../assets/images/icon-zan.png" alt="" />{{item.likes}}</span>
								</div>
							</div>

						</li>
					</ul>
				</div><!-- wik-left-m1 end -->
				<div class="wik-listCon" :class="moreClass?'moreShow':''">
					<div class="wik-leftList-gounp" v-for="(item,m) in leftList" :key="m">
						<div class="wik-left-m2 mt-20" v-if="item.imagesLayout == '1'">
							<div class="wik-left-m2-title">{{item.title}}</div>
							<div class="wik-left-m2-listBox">
								<ul class="wik-left-m2-list clearfix">
									<li v-for="(item2,d) in item.imageArray" :key="d">
										<a href="#">
											<img :src="imgUrl+item2" alt="" />
										</a>
									</li>
								</ul>
							</div>
							<div class="wik-left-m1-bottom clearfix">
								<div class="wik-left-m1-bl fl">{{item.labels}}</div>
								<div class="wik-left-m1-br fr">
									<span><img src="../../assets/images/icon-see.png" alt="" />{{item.views}}</span>
									<span><img src="../../assets/images/icon-zan.png" alt="" />{{item.likes}}</span>
								</div>
							</div>
						</div>
						<div class="wik-left-m3-list2" v-else>
							<a href="#">
								<div class="wik-left-m3-listImg">
									<img :src="imgUrl+item.images" alt="" />
								</div>
								<div class="wik-left-m3-con">
									<h3>{{item.title}}</h3>
									<p>{{item.articleSummary}}</p>
									<div class="wik-left-m3-bottom clearfix">
										<div class="wik-left-m3-bl fl">{{item.labels}}</div>
										<div class="wik-left-m1-br fr">
											<span><img src="../../assets/images/icon-see.png" alt="" />{{item.views}}</span>
											<span><img src="../../assets/images/icon-zan.png" alt="" />{{item.likes}}</span>
										</div>
									</div>
								</div>
							</a>
						</div>
					</div><!-- wik-leftList-gounp end -->
				</div><!-- wik-listCon end -->
				
				<div class="wik-left-more mt-20" @click="moreList" v-if="leftList.length>6">
					<span v-if="moreClass">已经到底...</span>
					<span v-else>加载更多...</span>
				</div>
			</div><!-- wik-left end -->

			<div class="wik-right fr">
				<div class="wik-right-tt">
					<div class="wik-right-tt-tit">
						<img src="../../assets/images/icon-hot-huo.png" alt="" />头条热榜
					</div>
					<ul class="wik-right-tt-list">
						<li v-for="(item,h) in ttList" :key="h">
							<i>{{h+1}}</i>
							<div class="wik-right-tt-text">
								<a href="#">{{item.title}} </a><span class="wik-right-tt-hot" v-if='h == 0'>热</span>
							</div>
						</li>
					</ul>
				</div><!-- wik-right-tt end -->
				<div class="wik-right-kx mt-15">
					<div class="wik-right-kx-tit">
						<span>快讯</span>
					</div>
					<div class="wik-right-kx-listBox mt-15" ref="listBox">
						<ul class="wik-right-kx-list">
							<li v-for="(item,g) in kxList" :key="g">
								<a href="#">
									<h4>{{item.pageDesc}}</h4>
									<p>{{item.updateTime}}</p>
								</a>
							</li>
						</ul>
					</div>
				</div><!-- wik-right-kx end -->
			</div><!-- wik-right end -->
		</div><!-- wik-main end  -->

		<div class="i-m9-wrap mt-50">
			<div class="i-m9 wrap clearfix">
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_03.png" alt="" class="i-m9-icon" />
					<div class="i-m9-info">
						<h4>全球品牌总数</h4>
						<p>300000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_06.png" alt="" class="i-m9-icon" />
					<div class="i-m9-info">
						<h4>城市精选商家</h4>
						<p>100000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_09.png" alt="" class="i-m9-icon" />
					<div class="i-m9-info">
						<h4>行业品牌榜单</h4>
						<p>5000<em>+</em></p>
					</div>
				</div>
			</div>
		</div><!-- i-m9-wrap end -->

		<Footer></Footer>
		<el-backtop style="width:50px;height:50px;">
			<div style="{
          height: 100%;
          width: 100%;
          background: #da0505 ;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
          z-index: 9999;
        }">
				<img src="../../assets/images/backtop.png" alt="" style="vertical-align: top;">
			</div>
		</el-backtop>
	</div>
</template>

<script>
	import {
		banner,
		listPart
	} from "../../api/wikipedia.js";

	import Footer from "@/components/footer";
	import Header from "@/components/header";
	export default {
		name: "wiki",
		components: {
			Header,
			Footer
		},
		data() {
			return {
				bannerHeight: 0,
				screenWidth: 0,

				mobilePop: false, //手机版弹层
				index: 0, //当前显示的是第几张图片
				timer: null,
				duration: 3000,
				bannerList: [],
				leftList: [], //左侧文章总列表
				leftList1: [], //左侧文章列表1
			
				moreClass: false, //列表加载更多
				timer2: null,
				quickIndex: 0,
				quick: [{
						id: 0,
						icon: require('../../assets/images/wik-top-icon1.png'),
						name: 'TOP热榜',
					},
					{
						id: 4,
						icon: require('../../assets/images/wik-top-icon2.png'),
						name: '品牌财富',
					},
					{
						id: 2,
						icon: require('../../assets/images/wik-top-icon3.png'),
						name: '生活百科',
					},
					{
						id: 3,
						icon: require('../../assets/images/wik-top-icon4.png'),
						name: '旅游娱乐',
					},
					{
						id: 6,
						icon: require('../../assets/images/wik-top-icon5.png'),
						name: '名校教育',
					},
					{
						id: 1,
						icon: require('../../assets/images/wik-top-icon6.png'),
						name: '零售电商',
					},
					{
						id: 5,
						icon: require('../../assets/images/wik-top-icon7.png'),
						name: '装修风格',
					},
					{
						id: 7,
						icon: require('../../assets/images/wik-top-icon8.png'),
						name: '养食养生',
					}
				],
				ttList: [], //头条
				kxList: [], //快讯

			};
		},

		created() {
			this.initPage();
		},

		computed: {},
		mounted() {
			this.autoCycle()
		},

		methods: {
			initPage() {
				this.getBanner()
				this.getList()
				this.getList2()
				this.getList3()
			},

			//左侧更多
			moreList() {
				this.moreClass = true
			},


			autoCycle() {
				//拿到相关元素
				const wrapper = this.$refs.listBox
				//window.requestAnimationFrame()或许是更好的选择，但是有点快，
				//如果要慢的话，可能需要写额外的判断或函数（difftime）
				//优点是 减少回流和重塑。 缺点是 在主线程，如果卡的话会影响动画。
				this.timer2 = setInterval(() => {
					// 元素自增距离顶部1像素
					wrapper.scrollTop += 1
					// 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
					if (wrapper.clientHeight + wrapper.scrollTop == wrapper.scrollHeight) {
						// 重置table距离顶部距离。值=(滚动到底部时，距离顶部的大小) - 整个高度/2
						wrapper.scrollTop = wrapper.scrollTop - wrapper.scrollHeight / 2
					}
				}, 50)
			},

			//轮播图
			getBanner() {
				banner().then(res => {
					console.log(res.data.rows,'轮播图');
					this.bannerList = res.data.rows
				})
			},

			//左边文章列表
			getList() {
				listPart({
					channelName: 0,
					moduleType: 1, //模块位置，1:左侧文章列表,2:头条热榜,3:快讯
				}).then(res => {
					console.log(res.data.rows, '文章列表');
					var dataCon = res.data.rows
					this.leftList = dataCon
					this.leftList1 = dataCon.slice(0, 3);
					

				})
			},

			//右边头条
			getList2() {
				listPart({
					channelName: 0,
					moduleType: 2, //模块位置，1:左侧文章列表,2:头条热榜,3:快讯
				}).then(res => {
					console.log(res.data.rows, '头条');
					var dataCon = res.data.rows
					this.ttList = dataCon
				})
			},

			//右边快讯
			getList3() {
				listPart({
					channelName: 0,
					moduleType: 3, //模块位置，1:左侧文章列表,2:头条热榜,3:快讯
				}).then(res => {
					console.log(res.data.rows, '快讯');
					var dataCon = res.data.rows
					this.kxList = dataCon
				})
			},

			//点击快捷菜单
			quickBtn(item, q) {
				console.log(item.id, q)
				this.quickIndex = q
				const id = item.id
				listPart({
					channelName: id,
					moduleType: 1, //模块位置，1:左侧文章列表,2:头条热榜,3:快讯
				}).then(res => {
					console.log(res.data.rows, '文章列表');
					var dataCon = res.data.rows
					this.leftList = dataCon
					this.leftList1 = dataCon.slice(0, 3);
					this.leftList3 = dataCon
					this.moreClass = false
				})

				listPart({
					channelName: id,
					moduleType: 2, //模块位置，1:左侧文章列表,2:头条热榜,3:快讯
				}).then(res => {
					var dataCon = res.data.rows
					this.ttList = dataCon
				})

				listPart({
					channelName: id,
					moduleType: 3, //模块位置，1:左侧文章列表,2:头条热榜,3:快讯
				}).then(res => {
					var dataCon = res.data.rows
					this.kxList = dataCon
				})
			},



			//跳转链接
			m8arrowLink() {
				this.$router.push({
					path: '/project-detail/project-detail'
				});
			},

		},
	}
</script>

<style>
	@import "../../assets/css/wikipedia.css";
</style>